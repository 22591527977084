
// @ is an alias to /src

import FlatButton from "@/components/atomics/FlatButton.vue";
import { defineComponent, onMounted, reactive, toRef, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "ErrorPage",
  components: {
    FlatButton,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      title: "",
      message: "",
    });

    const goNext = () => {
      const errorMessage = route.query.error;
      if (
        errorMessage === t("errors.errorBackToSignAgain") ||
        errorMessage === t("errors.errorBackToCreateAgain")
      )
        router.push({
          path: "/dashboard",
        });
      else router.push({ path: "/" });
    };

    return {
      ...toRefs(state),
      route,
      goNext,
    };
  },
});
